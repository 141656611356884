.studentBanner {
  background: url("./../../../assets/images/bg/student-mobile.png"),
    lightgray -39.073px -114px / 106.65% 128.5% no-repeat;
  background-image: url("./../../../assets/images/bg/student-mobile.png"),
    lightgray -39.073px -114px / 106.65% 128.5% no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (min-width: 768px) {
  .studentBanner {
    background: url("./../../../assets/images/bg/student-laptop.png"),
      lightgray -39.073px -114px / 106.65% 128.5% no-repeat;
    background-image: url("./../../../assets/images/bg/student-laptop.png"),
      lightgray -39.073px -114px / 106.65% 128.5% no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
