.heroBgNew {
  background: url("./../../../assets/images/bg/mobile-banner.png"), no-repeat,
    linear-gradient(
      259deg,
      rgba(4, 151, 174, 0.92) 3.7%,
      rgba(198, 52, 52, 0.92) 97.78%
    );
  background-image: url("./../../../assets/images/bg/mobile-banner.png"),
    no-repeat,
    linear-gradient(
      259deg,
      rgba(4, 151, 174, 0.92) 3.7%,
      rgba(198, 52, 52, 0.92) 97.78%
    );
  background-repeat: no-repeat;
  background-size: cover;
}

.leading-72px {
  line-height: 36px !important;
}

@media only screen and (min-width: 768px) {
  .heroBgNew {
    background: url("./../../../assets/images/bg/laptop-banner-t.png"),
      no-repeat, linear-gradient(259deg, #0497ae 3.7%, #c63434 97.78%);
    background-image: url("./../../../assets/images/bg/laptop-banner-t.png"),
      no-repeat, linear-gradient(259deg, #0497ae 3.7%, #c63434 97.78%);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
}

@media only screen and (min-width: 768px) {
  .leading-72px {
    line-height: 72px !important;
  }
}

.gradientText {
  background: linear-gradient(180deg, #ed4c41 0%, #fb6f60 61.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.joinOurNetworkBtn {
  background: linear-gradient(180deg, #ffd844 0%, #ffbc00 100%);
  /* Shadow-md */

  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.1),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
}

.loaderBg {
  background: linear-gradient(
    259deg,
    rgba(4, 151, 174, 0.92) 3.7%,
    rgba(198, 52, 52, 0.92) 97.78%
  );
}
