.stats-card {
    background: linear-gradient(133.09deg, #feeeec 8.11%, #edf9f9 101.96%);
    border-radius: 8px;
}

.stats-height {
    height: 48px;
}

@media only screen and (max-width: 1315px) {
    .stats-height {
        height: 72px;
    }
}

@media only screen and (max-width: 1280px) {
    .stats-height {
        height: 48px;
    }
}
