.home-network-card {
    /* Shades/50 */

    background: #ffffff;
    /* Shadow-lg */

    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
        0px 4px 6px -4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    max-width: 408px;
}
