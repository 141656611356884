@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff00;
  border-radius: 10px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #301709;
  border-radius: 10px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #edd486; }

::-webkit-scrollbar {
  width: 0px;
  height: 0px; }

/*Theme Colors*/
/*Responsiveness*/
/*Theme Colors*/
/*Theme Colors*/
.indulgence-list::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  top: 0px;
  display: block;
  width: 2px;
  content: "";
  background: #e7e6e5; }

.line-border-center {
  border-color: #e7e6e5; }

@media only screen and (max-width: 1168px) {
  .indulgence-list::after {
    /* left: 0px; */ } }

@media only screen and (max-width: 768px) {
  .indulgence-list::after {
    /* left: -12px; */ } }

.indulgence-bg {
  background-image: url("./../../../assets/images/bg/indulgenceBg.png");
  background-repeat: no-repeat;
  background-size: cover; }

.white-card {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1), 0px 0px 10px 0px rgba(0, 0, 0, 0.05); }

.red-card {
  border-radius: 56px;
  border: 1px solid var(--caarya-red-300, #ff7e6e);
  background: rgba(255, 233, 229, 0.3);
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1), 0px 0px 16px 0px rgba(0, 0, 0, 0.05); }

.yellow-card {
  border-radius: 56px;
  border: 1px solid var(--fusion-500, #ffbc00);
  background: rgba(253, 247, 233, 0.3);
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1), 0px 0px 16px 0px rgba(0, 0, 0, 0.05); }

#sentence-wrapper {
  position: relative; }

.words {
  width: 100%;
  height: 75px; }

@media only screen and (max-width: 1280px) {
  .words {
    height: 145px; } }

@media only screen and (max-width: 1024px) {
  .words {
    height: 45px; } }

.words-1 span {
  position: absolute;
  opacity: 0;
  overflow: hidden;
  width: 100%;
  left: 0;
  -webkit-animation: rotateWord 12s linear infinite 0s;
  -moz-animation: rotateWord 12s linear infinite 0s;
  -o-animation: rotateWord 12s linear infinite 0s;
  -ms-animation: rotateWord 12s linear infinite 0s;
  animation: rotateWord 12s linear infinite 0s; }

.words-1 span:nth-child(2) {
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  -o-animation-delay: 3s;
  -ms-animation-delay: 3s;
  animation-delay: 3s; }

.words-1 span:nth-child(3) {
  -webkit-animation-delay: 6s;
  -moz-animation-delay: 6s;
  -o-animation-delay: 6s;
  -ms-animation-delay: 6s;
  animation-delay: 6s; }

.words-1 span:nth-child(4) {
  -webkit-animation-delay: 9s;
  -moz-animation-delay: 9s;
  -o-animation-delay: 9s;
  -ms-animation-delay: 9s;
  animation-delay: 9s; }

@-webkit-keyframes rotateWord {
  0% {
    opacity: 0; }
  2% {
    opacity: 0;
    -webkit-transform: translateY(-5px); }
  5% {
    opacity: 1;
    -webkit-transform: translateY(0px); }
  17% {
    opacity: 1;
    -webkit-transform: translateY(0px); }
  20% {
    opacity: 0;
    -webkit-transform: translateY(5px); }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-moz-keyframes rotateWord {
  0% {
    opacity: 0; }
  2% {
    opacity: 0;
    -moz-transform: translateY(-5px); }
  5% {
    opacity: 1;
    -moz-transform: translateY(0px); }
  17% {
    opacity: 1;
    -moz-transform: translateY(0px); }
  20% {
    opacity: 0;
    -moz-transform: translateY(5px); }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-o-keyframes rotateWord {
  0% {
    opacity: 0; }
  2% {
    opacity: 0;
    -o-transform: translateY(-5px); }
  5% {
    opacity: 1;
    -o-transform: translateY(0px); }
  17% {
    opacity: 1;
    -o-transform: translateY(0px); }
  20% {
    opacity: 0;
    -o-transform: translateY(5px); }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-ms-keyframes rotateWord {
  0% {
    opacity: 0; }
  2% {
    opacity: 0;
    -ms-transform: translateY(-5px); }
  5% {
    opacity: 1;
    -ms-transform: translateY(0px); }
  17% {
    opacity: 1;
    -ms-transform: translateY(0px); }
  20% {
    opacity: 0;
    -ms-transform: translateY(5px); }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes rotateWord {
  0% {
    opacity: 0; }
  2% {
    opacity: 0;
    transform: translateY(-5px); }
  5% {
    opacity: 1;
    transform: translateY(0px); }
  17% {
    opacity: 1;
    transform: translateY(0px); }
  20% {
    opacity: 0;
    transform: translateY(5px); }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes float {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(-10px); }
  100% {
    transform: translateY(0px); } }

.globe-animate {
  transform: translateY(0px);
  animation: float 6s ease-in-out infinite; }

.arrows svg {
  animation: animate 2s infinite; }

.arrow svg:nth-child(2) {
  animation-delay: 0.2s; }

.arrow svg:nth-child(3) {
  animation-delay: 0.4s; }

.arrow svg:nth-child(4) {
  animation-delay: 0.6s; }

@keyframes animate {
  0% {
    opacity: 0;
    transform: translateY(-20px); }
  25% {
    opacity: 0.5; }
  50% {
    opacity: 1; }
  75% {
    opacity: 0.5; }
  100% {
    opacity: 0;
    transform: translateY(20px); } }

.contract-list::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0px;
  top: 0px;
  display: block;
  width: 2px;
  content: "";
  background: #9c9a96; }

.contract-number {
  left: -104px; }

@media only screen and (max-width: 1400px) {
  .contract-number {
    left: -84px; } }

@media only screen and (max-width: 1280px) {
  .contract-number {
    left: -44px; } }

@media only screen and (max-width: 1168px) {
  .contract-number {
    left: -42px; }
  .contract-list::after {
    left: 0px; } }

@media only screen and (max-width: 768px) {
  .contract-number {
    left: -30px; }
  .contract-list::after {
    left: -12px; } }

.purple-heading {
  color: #c44eb9; }

.purple-description {
  color: #451451; }

.purple-program-gradient {
  background: linear-gradient(183.01deg, #f9e3fc 5.89%, #ffffff 98.63%); }

.aqua-heading {
  color: #116e84; }

.aqua-description {
  color: #002739; }

.aqua-program-gradient {
  background: linear-gradient(183.01deg, #c6f8f9 5.89%, #ffffff 98.63%); }

.green-heading {
  color: #008b46; }

.green-description {
  color: #08422f; }

.green-program-gradient {
  background: linear-gradient(183.01deg, #d8f3d7 5.89%, #ffffff 98.63%); }

.blue-heading {
  color: #4f57b7; }

.blue-description {
  color: #4f57b7; }

.blue-program-gradient {
  background: linear-gradient(183deg, #c3cef4 5.89%, #fff 98.63%); }

.yellow-heading {
  color: #ba7507; }

.yellow-description {
  color: #532a01; }

.yellow-program-gradient {
  background: linear-gradient(183.01deg, #fdeecd 5.89%, #ffffff 98.63%); }

.centered-hexagon {
  background: radial-gradient(50% 50% at 50% 50%, #e72113 0%, #6d1413 100%);
  transform: rotate(90deg); }

.hexagon {
  height: 166px;
  width: 186px;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  z-index: 20; }

.side-hexagon {
  background: linear-gradient(180deg, rgba(252, 214, 207, 0.05) 0%, rgba(252, 214, 207, 0.25) 100%);
  backdrop-filter: blur(44px);
  /* Note: backdrop-filter has minimal browser support */
  transform: rotate(90deg); }

.hexagon-wrapper {
  background: #ed4c41;
  background: radial-gradient(circle, #ed4c41 0%, white 40%); }

.bg-hexagon {
  padding: 56px; }

.side-bl {
  background: linear-gradient(239deg, rgba(198, 248, 249, 0.6) 11.85%, rgba(237, 255, 255, 0.4) 85.1%);
  stroke-width: 1px;
  stroke: rgba(254, 184, 177, 0.3);
  backdrop-filter: blur(44px);
  right: 55%;
  top: -50%; }

.side-tl {
  background: linear-gradient(239deg, rgba(195, 206, 244, 0.6) 11.85%, rgba(250, 251, 255, 0.4) 85.1%);
  stroke-width: 1px;
  stroke: rgba(254, 184, 177, 0.3);
  backdrop-filter: blur(44px);
  top: -50%;
  left: 55%; }

.side-br {
  background: linear-gradient(239deg, rgba(253, 238, 205, 0.6) 11.85%, rgba(253, 247, 233, 0.5) 85.1%);
  stroke-width: 1px;
  stroke: rgba(254, 184, 177, 0.3);
  backdrop-filter: blur(44px);
  right: 55%;
  bottom: -50%; }

.side-tr {
  background: linear-gradient(239deg, rgba(252, 220, 204, 0.6) 11.85%, rgba(255, 241, 235, 0.5) 85.1%);
  stroke-width: 1px;
  stroke: rgba(254, 184, 177, 0.3);
  backdrop-filter: blur(44px);
  bottom: -50%;
  left: 55%; }

.side-t {
  background: linear-gradient(239deg, rgba(249, 227, 252, 0.6) 11.85%, rgba(253, 242, 255, 0.5) 85.1%);
  stroke-width: 1px;
  stroke: rgba(254, 184, 177, 0.3);
  backdrop-filter: blur(44px);
  right: -50%; }

.side-b {
  background: linear-gradient(239deg, rgba(216, 243, 215, 0.6) 11.85%, rgba(233, 252, 232, 0.4) 85.1%);
  stroke-width: 1px;
  stroke: rgba(254, 184, 177, 0.3);
  backdrop-filter: blur(44px);
  left: -50%; }

@media only screen and (max-width: 650px) {
  .bg-hexagon {
    padding: 48px; }
  .side-bl {
    right: 50%;
    top: -45%; }
  .side-tl {
    top: -45%;
    left: 50%; }
  .side-br {
    right: 50%;
    bottom: -45%; }
  .side-tr {
    bottom: -45%;
    left: 50%; }
  .side-t {
    right: -45%; }
  .side-b {
    left: -45%; } }

@media only screen and (max-width: 530px) {
  .bg-hexagon {
    padding: 40px; }
  .hexagon {
    height: 126px;
    width: 146px; } }

@media only screen and (max-width: 440px) {
  .hexagon {
    height: 106px;
    width: 126px; }
  .side-hexagon {
    font-size: 12px; } }

@media only screen and (max-width: 380px) {
  .hexagon {
    height: 86px;
    width: 106px; }
  .side-hexagon {
    font-size: 10px;
    line-height: 14px !important; } }

.help-center-form {
  color: #2b1111; }
  .help-center-form .card {
    max-width: 700px;
    box-shadow: 0px 4px 30px 3px rgba(44, 0, 9, 0.2);
    border-radius: 5px; }
  .help-center-form .fields label {
    font-weight: 700; }
  .help-center-form .fields .form-optional {
    color: "#808080"; }

.help-center-modal .modal-container {
  height: 100vh;
  width: 100vw;
  z-index: 70;
  backdrop-filter: blur(4px); }

.help-center-modal .modal-body {
  box-shadow: 0px 4px 30px 3px rgba(44, 0, 9, 0.2);
  border-radius: 10px;
  background-color: white;
  position: relative;
  width: 90vw;
  z-index: 100;
  padding: 20px; }

@media screen and (min-width: 600px) {
  .help-center-modal .modal-body {
    position: relative;
    width: 90vw;
    max-width: 460px;
    z-index: 100;
    padding: 20px; } }

.red-text {
  color: #c63434; }

.blue-text {
  color: #116eb4; }

.orange-text {
  color: #ce5511; }

.yellow-text {
  color: #ba7507; }

.box-color {
  background-color: #fed6d2;
  color: #6d1413; }

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

.modal-content {
  width: 90vw;
  height: 75vh;
  background-color: white;
  border-radius: 14px;
  margin-right: 10px;
  max-height: 420px; }

.modal-container input {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 21px; }

.modal-container input:focus {
  outline: none; }

.modal-container input::placeholder {
  font-family: "Poppins";
  color: #c2c2c2; }

.modal-container h2 {
  font-size: 21px;
  margin-top: 30px;
  max-width: 200px; }

.modal-container p {
  font-size: 10px;
  margin-left: 30px;
  margin-right: 30px;
  max-width: 200px; }

.modal-container input::placeholder {
  font-size: 10px; }

.modal-container input {
  font-size: 10px; }

.modal-container #input-image {
  height: 11px; }

.modal-container #subscription-button {
  height: 45px;
  width: 111px;
  margin-top: 25px;
  margin-right: 5px; }

.modal-container #subscribe-text {
  font-size: 12px;
  margin-left: -100px;
  margin-top: -5px; }

.modal-container #curve {
  height: 250px;
  width: 38vw;
  margin-bottom: -300px;
  margin-left: -10px;
  content: url("../../images/AboutUs/subscribe-curve-mobile.png"); }

.modal-container #plane {
  height: 29px;
  width: 62px; }

.modal-container #close {
  height: 32px;
  width: 32px; }

.modal-container #star {
  height: 71px;
  width: 96px;
  margin-top: 60px; }

.modal-container #error-message {
  color: #ff1606;
  font-weight: 400;
  font-style: italic;
  margin-left: 5px;
  font-size: 9px; }

.modal-container #error-icon {
  height: 10px;
  width: 10px; }

@media screen and (min-width: 600px) {
  .modal-content {
    width: 70vw;
    height: 70vh;
    background-color: white;
    border-radius: 14px;
    max-height: 500px;
    min-width: 700px; }
  .modal-container h2 {
    font-size: 38px;
    margin-top: -25px;
    max-width: fit-content; }
  .modal-container p {
    font-size: 16px;
    max-width: fit-content; }
  .modal-container input::placeholder {
    font-size: 21px; }
  .modal-container input {
    font-size: 21px; }
  .modal-container #input-image {
    height: 25px; }
  .modal-container #subscription-button {
    height: 101px;
    width: 246px;
    margin-right: -10px;
    margin-top: -15px; }
  .modal-container #subscribe-text {
    font-size: 28px;
    margin-left: -200px;
    margin-top: -10px; }
  .modal-container #curve {
    height: 350px;
    width: 500px;
    margin-bottom: -215px;
    margin-left: 0px;
    content: url("../../images/AboutUs/subscribe-curve.png"); }
  .modal-container #plane {
    height: 50px;
    width: 106px; }
  .modal-container #close {
    height: 50px;
    width: 50px; }
  .modal-container #star {
    height: 116px;
    width: 158px; }
  .modal-container #error-message {
    font-weight: 400;
    margin-left: 10px;
    font-size: 16px;
    margin-left: 10px; }
  .modal-container #error-icon {
    height: 20px;
    width: 20px; } }

.heroBg {
  background-image: url("../../images/bg/heroBg.png");
  background-repeat: no-repeat;
  background-size: cover; }

.enrollBg {
  background-image: url("../../images/bg/enrollBg.png");
  background-repeat: no-repeat;
  background-size: cover; }

.student-testimonial-card {
  background: #ffffff;
  /* Shadow-lg */
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  max-width: 408px; }

.student-name {
  border-left: 4px solid #ed4c41; }

.getProfiledBtn {
  /* Neutral/700 */
  color: #42413e;
  background: linear-gradient(180deg, #ffd844 0%, #ffbc00 100%);
  /* Shadow-md */
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.1), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 32px; }

.getProfiledBtn-red {
  /* Neutral/700 */
  border-radius: 40px;
  background: linear-gradient(98deg, #ff7e6e 0.29%, #c63434 100.29%);
  /* Shadow-lg */
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 32px; }

.getProfiledBtn-light {
  border-radius: 40px;
  background: #fdf7e9;
  color: #ba7507;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 24px */
  letter-spacing: 0.4px; }

.vertical-image {
  background: #ff7e6e;
  mix-blend-mode: plus-darker; }

.pod-hexagon {
  background: linear-gradient(141.44deg, #ffeec1 13.93%, #ffd8d4 92.42%);
  border-radius: 8px;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%); }

.pod-tag {
  /* Caarya Accent/200 */
  background: #ffe99a;
  border-radius: 96px; }

.gesture-container {
  width: 100vw;
  height: 100vh; }
  .gesture-container .page {
    position: absolute;
    width: 100%;
    height: 100%;
    will-change: transform; }
    .gesture-container .page > div {
      will-change: transform; }

.startupBanner {
  background: linear-gradient(122deg, #ffe9e5 0%, #edffff 69.08%); }

.stargaze-gradient {
  background: linear-gradient(92deg, #008b46 0%, #75dd67 58.23%, #2bb656 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.pod-gradient {
  background: linear-gradient(92deg, #ff7e6e 0%, #ffbc00 58.23%, #f57d34 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.stargaze-text {
  background: linear-gradient(92deg, #008b46 0%, #75dd67 58.23%, #2bb656 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.pod-text {
  background: linear-gradient(92deg, #ff7e6e, 0%, #ffbc00 58.23%, #f57d34 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.bannermenu-main {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 20; }

.hamburger {
  cursor: pointer;
  width: 32px;
  height: 32px;
  transition: all 0.25s; }

.hamburger__top-bun,
.hamburger__bottom-bun {
  content: "";
  position: absolute;
  width: 24px;
  height: 2px;
  background: black;
  transform: rotate(0);
  transition: all 0.5s; }
  .hamburger__top-bun.dark,
  .hamburger__bottom-bun.dark {
    background: white; }

.hamburger:hover [class*="-bun"] {
  background: #323232; }
  .hamburger:hover [class*="-bun"].dark {
    background: white; }

.hamburger__top-bun {
  transform: translateY(-5px); }

.hamburger__bottom-bun {
  transform: translateY(3px); }

.open {
  transform: rotate(90deg);
  transform: translateY(-1px); }

.open .hamburger__top-bun {
  transform: rotate(45deg) translateY(0px); }

.open .hamburger__bottom-bun {
  transform: rotate(-45deg) translateY(0px); }

.nav-text:hover {
  /* color: #3c0a14;
     */
  text-decoration: underline; }

.mobile-nav-menu {
  background: linear-gradient(117.35deg, rgba(145, 145, 145, 0.2) 33.67%, rgba(107, 105, 105, 0.05) 68.01%), #ffffff;
  position: fixed;
  top: 100vh;
  left: 0;
  height: 100vh;
  width: 100vw;
  transition: 0.3s ease-in;
  opacity: 0; }

.visible {
  top: 0;
  opacity: 1; }

.menu-yellow {
  color: #3c0a14; }

/*Theme Colors*/
.modals-component {
  background: #f8f8f9;
  mix-blend-mode: normal;
  box-shadow: 0px -10px 20px -13px rgba(0, 0, 0, 0.15);
  border-radius: 20px 20px 0px 0px; }

.input-field-box {
  background: #ffffff;
  mix-blend-mode: normal;
  border-radius: 10px; }

.input-field-box-selected {
  background: #edd486;
  mix-blend-mode: normal;
  border-radius: 10px; }

.theme-container {
  background-color: #fbf6e5;
  border-radius: 40px; }

.yellow-btn {
  background: linear-gradient(180deg, #ffd844 0%, #ffbc00 100%);
  /* Shadow-md */
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1); }

.poppins {
  font-family: "Poppins", sans-serif; }

.inter {
  font-family: "Inter", sans-serif; }

.default-plain {
  background-color: #0d7b91; }

.container-rounded {
  border-radius: 5em; }

.container-b-rounded {
  border-radius: 0 0 5em 5em; }

.gradient {
  background: linear-gradient(15deg, #0497ae, #116e84); }

.cyan-card-gradient {
  background: linear-gradient(70.27deg, #087184 5.79%, #38d3dd 171.12%); }

.gradient-color {
  color: #0a849b;
  border-color: #0a849b; }

.red-plain {
  background-color: #6d1413; }

.red-gradient {
  background-color: #6d1413;
  background-image: linear-gradient(15deg, #ff7e6e, #6d1413); }

.website-bg {
  background-color: white; }

.aqua-100 {
  color: #c6f8f9; }

.aqua-200 {
  color: #a5f2f2; }

.aqua-300 {
  color: #ff7e6e; }

.red-500 {
  color: #e05858ff; }

.bg-red-500 {
  background-color: #e05858ff; }

.aqua-400 {
  color: #0497ae; }

.aqua-500 {
  color: #116e84; }

.red-400 {
  color: #c63434; }

.bg-red-light {
  background: #fff4f2; }

.bg-blue-light {
  background: #edffff; }

.bg-purple-light {
  background: #f9e3fc; }

.bg-yellow-light {
  background: #fdeecd; }

.bg-fusion-light {
  background: #fdeecd; }

.bg-fusion-purple-light {
  background: #eaeaf9; }

.bg-symmetry-light {
  background: #d8f3d7; }

.red-solid {
  background: #6d1413; }

.red-text-gradient {
  color: #c63434;
  border-color: #c63434; }

.red-text-dark {
  color: #c63434; }

.labs-text-dark {
  color: #232968;
  border-color: #232968; }

.waves-text-dark {
  color: #116e84;
  border-color: #116e84; }

.fusion-text-dark {
  color: #532a01;
  border-color: #532a01; }

.fusion-takeaway-bg {
  background-color: #ffd844; }

.venture-takeaway-bg {
  background: linear-gradient(76.04deg, #008b46 -0.09%, #75dd67 99.9%); }

.venture-text-dark-green {
  color: #08422f; }

.venture-text-light-green {
  color: #008b46; }

.industry-text-dark-green {
  color: #451451; }

.industry-text-light-green {
  color: #903795; }

.blue-gradient-2 {
  background-color: #0497ae; }

.yellow-gradient {
  background-color: #e9c02a;
  background-image: linear-gradient(15deg, #ffc96d 0%, #ffb952 100%); }

.fusion-yellow-gradient {
  background-color: #ba7507;
  background-image: linear-gradient(300deg, #ffbc00 0%, #542a02 74%); }

.fusion-yellow-dark {
  background-color: #ffbc00; }

.labs-plain {
  background-color: #1c2135; }

.labs-gradient {
  background-color: #232968;
  background-image: linear-gradient(315deg, #232968 0%, #1c2135 74%); }

.wave-plain {
  background-color: #002739; }

.wave-gradient {
  background-color: #083d4f;
  background-image: linear-gradient(315deg, #083d4f 0%, #002739 74%); }

.fusion-plain {
  background-color: #532a01; }

.communities-card {
  background: linear-gradient(70.27deg, #087184 5.79%, #38d3dd 171.12%); }

.fusion-gradient {
  background-color: #532a01;
  background-image: linear-gradient(315deg, #ffbc00 0%, #542a02 74%); }

.ugc-plain {
  background-color: #008b46; }

.ugc-gradient {
  background-color: #75dd67;
  background-image: linear-gradient(315deg, #75dd67 0%, #008b46 74%); }

.purple-gradient {
  background-color: #451451;
  background: linear-gradient(104.77deg, #451451 6.51%, #c44eb9 108.94%); }

.labs-light {
  color: #ebedf7; }

.dropdown .dropdown-menu {
  display: block;
  z-index: 10; }

.svgpath-1 {
  opacity: 0.300000003;
  -webkit-animation: fadeout 2s ease-in alternate infinite;
  -moz-animation: fadeout 2s ease-in alternate infinite;
  animation: fadeout 2s ease-in alternate infinite; }

.svgpath-2 {
  opacity: 0.100000001;
  -webkit-animation: fadein 2s ease-in alternate infinite;
  -moz-animation: fadein 2s ease-in alternate infinite;
  animation: fadein 2s ease-in alternate infinite; }

.svgpath-3 {
  opacity: 0.300000003;
  -webkit-animation: fadeout 2s ease-in alternate infinite;
  -moz-animation: fadeout 2s ease-in alternate infinite;
  animation: fadeout 2s ease-in alternate infinite; }

@-webkit-keyframes fadein {
  0% {
    opacity: 0.100000001; }
  50% {
    opacity: 0.300000003; }
  100% {
    opacity: 0.100000001; } }

@-moz-keyframes fadein {
  0% {
    opacity: 0.100000001; }
  50% {
    opacity: 0.300000003; }
  100% {
    opacity: 0.100000001; } }

@keyframes fadein {
  0% {
    opacity: 0.100000001; }
  50% {
    opacity: 0.300000003; }
  100% {
    opacity: 0.100000001; } }

@-webkit-keyframes fadeout {
  0% {
    opacity: 0.300000003; }
  50% {
    opacity: 0.100000001; }
  100% {
    opacity: 0.300000003; } }

@-moz-keyframes fadeout {
  0% {
    opacity: 0.300000003; }
  50% {
    opacity: 0.100000001; }
  100% {
    opacity: 0.300000003; } }

@keyframes fadeout {
  0% {
    opacity: 0.300000003; }
  50% {
    opacity: 0.100000001; }
  100% {
    opacity: 0.300000003; } }

.card-shadow {
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(77, 77, 77, 0.1);
  border-radius: 10px; }

.community-event-card {
  box-shadow: 0px 4px 20px rgba(77, 77, 77, 0.15);
  border-radius: 10px; }

.slick-prev:before,
.slick-next:before {
  display: none; }

.future-caarya-program-cards {
  background: white;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  max-width: 408px;
  /* height: 500px;
    position: relative; */ }

.future-program-tag {
  background: linear-gradient(94.15deg, #ff7e6e 3.38%, #6d1413 100%);
  border-radius: 24px 16px 32px; }

.future-caarya-program-blob {
  background: linear-gradient(156.32deg, #fdeecd 2.09%, #ffbc00 101.27%);
  border-radius: 72px 240px 24px 24px;
  /* position: absolute;
    bottom: 0px; */ }

.cgc-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 28px;
  color: #ffffffff;
  /* white */
  background: #171a1fff;
  /* neutral-900 */
  opacity: 1;
  border: none;
  border-radius: 6px;
  /* border-l */ }

/* Hover */
.cgc-button:hover {
  color: #ffffffff;
  /* white */
  background: #262a33ff;
  /* neutral-750 */ }

/* Pressed */
.cgc-button:hover:active {
  color: #ffffffff;
  /* white */
  background: #323842ff;
  /* neutral-700 */ }

/* Disabled */
.cgc-button:disabled {
  opacity: 0.4; }

.bg-gray-850 {
  background-color: #323842ff; }

.event-card {
  border-radius: 6px;
  /* border-l */ }

.newsletter-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 26px;
  color: #9095a0ff;
  /* neutral-500 */
  background: #ffffffff;
  /* white */
  opacity: 1;
  border-radius: 4px;
  /* border-m */
  border-width: 1px;
  border-color: #9095a0ff;
  /* neutral-500 */
  border-style: solid; }

/* Hover */
.newsletter-btn:hover {
  color: #6e7787ff;
  /* neutral-550 */
  background: #ffffffff;
  /* white */ }

/* Pressed */
.newsletter-btn:hover:active {
  color: #565e6cff;
  /* neutral-600 */
  background: #ffffffff;
  /* white */ }

/* Disabled */
.newsletter-btn:disabled {
  opacity: 0.4; }

.bg-student-program {
  background: #f8f9faff; }

.student-program-card {
  background: #ffff;
  /* white */
  border-radius: 16px;
  box-shadow: 0px 4px 9px #e1e1e2; }

.help-center-bg {
  background: radial-gradient(37.36% 104.36% at 72.15% 59.26%, #fdeecd 0%, rgba(255, 243, 217, 0.61) 100%); }
