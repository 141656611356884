.footer {
  height: 20vh;
}

.footer-text {
  color: #6d1413;
}

.footer-item:hover {
}

.footer-item {
}

.footer-image:hover {
  transform: scale(1.2);
}
