.polaroid {
  background: #fff;
  padding: 1rem;
  padding-bottom: 3rem;
  box-shadow: 0 0.2rem 1.2rem rgba(0, 0, 0, 0.2);
}
.polaroid > img {
  max-width: 100%;
  height: auto;
}
.caption {
  font-size: 1.8rem;
  text-align: center;
  line-height: 2em;
}
.item {
  width: 70%;
  display: inline-block;
  filter: grayscale(50%);
  transform: scale(1, 1) rotate(5deg) !important;
}
