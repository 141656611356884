.exp-logo{
    transform: scale(1.5)
}

.fusion-logo{
    transform: scale(1.1)
}
.symmetry-logo{
    transform: scale(1.4)
}
.dawn-logo{

}
.wave-logo{

}
.labs-logo{
    transform: scale(0.9)
}


.verse-container{
    /* background: black; */
    margin: auto;
}

.verse-main-logo{
    /* background: black; */
    width: 100%;
    transform: scale(1.5)

}

.verse-vertical-logo{

}

.ht-gradient{
    position: relative;
    top: 50%;
}

.hb-gradient{
    position: relative;
}

.row-logos{
    /* background: yellow; */
}

.row-borders{
    /* background: green; */
}

.logo-col{
    /* background: orange; */
}

.border-col{
    /* background: blue; */
}


.logo-rotate-tl{
    transform: rotate(30deg);
    transform-origin: bottom right;
}

.logo-rotate-br{
    transform: rotate(30deg);
    transform-origin: top left;
}

.logo-anti-rotate-bl{
    transform: rotate(-30deg); 
    transform-origin: top left;
}

.logo-anti-rotate-tr{
    transform: rotate(-30deg); 
    transform-origin: bottom right;
}

.ed-gradient{
    background-color: #f57d34;
    background-image: linear-gradient(315deg, #f57d34 0%, #c44eb9 74%);
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: x-energyflow;
    animation-timing-function: linear;
}

.le-gradient{
    background-color: #c44eb9;
    background-image: linear-gradient(315deg, #c44eb9 0%, #816FE9  74%);
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: y-negitiveenergyflow;
    animation-timing-function: linear;
}

.ds-gradient{
    background-color: #2bb656;
    background-image: linear-gradient(315deg, #2bb656 0%, #f57d34 74%);
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: y-energyflow;
    animation-timing-function: linear;
}

.fl-gradient{
  background-color:  #ffbc00;
  background-image: linear-gradient(315deg,  #ffbc00 0%, #816FE9 74%);  
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: y-negitiveenergyflow;
  animation-timing-function: linear;
}

.wf-gradient{  
  background-color: #ffbc00;
  background-image: linear-gradient(315deg, #ffbc00 0%, #0497ae 74%);
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: x-negitiveenergyflow;
  animation-timing-function: linear;
}

.sw-gradient{
  background-color: #0497ae;
  background-image: linear-gradient(315deg, #0497ae 0%, #2bb656 74%);
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: y-energyflow;
  animation-timing-function: linear;
}

@keyframes x-energyflow {
    0% {
      background-position: 0px 0;
    }
    100% {
      background-position: 30em 0;
    }
  }

  @keyframes x-negitiveenergyflow {
    0% {
      background-position: 0px 0;
    }
    100% {
      background-position: -30em 0;
    }
  }

  @keyframes y-energyflow {
    0% {
      background-position: 0px 0;
    }
    100% {
      background-position: 0 30em;
    }
  }

  @keyframes y-negitiveenergyflow {
    0% {
      background-position: 0px 0;
    }
    100% {
      background-position: 0 -30em;
    }
  }